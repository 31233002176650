import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ChakraProvider } from '@chakra-ui/react'

import { AuthProvider } from './Contexts/AuthContext';

import LandingApp from './Page/LandingApp';
import NeverApp from './Page/NeverApp';
import LoginApp from './App/LoginApp';
import RegisterApp from './App/LoginApp/register';
import AimonApp from './App/AimonApp';
import ResultApp from './App/AimonApp/result';
import App from './App';
import { useEffect } from 'react';
import TermsPage from './Page/TermsPage';
import config from './config.js';

import './mymarkdown.css';

const Jump = ({ to, children, ...rest }) => {
  useEffect(() => {
    window.location.href = to;
  }, []);
  return (<div />);
};



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route index element={!config.dev ? <LandingApp /> : <App path="search" />} />
          {/* <Route path="neverxxx" element={<NeverApp />} /> */}
          <Route path="privacy" element={<Jump to="https://harolabo.com/privacy" />} />
          <Route path="termsofuse" element={<TermsPage page="termsofuse" />} />
          <Route path="commerce" element={<TermsPage page="commerce" />} />
          {/* <Route path="termsofuse" element={<Jump to="https://harolabo.com/pockemon-termsofuse" />} />
          <Route path="commerce" element={<Jump to="https://harolabo.com/pockemon-commerce" />} /> */}

          <Route path="signin" element={<App path="signin" />} />
          <Route path="result" element={<App path="result" />} />
          <Route path="search" element={<App path="search" />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
