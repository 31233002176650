import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { firebaseAuth } from '../firebase.js'

import config from '../config';

class MonshinService {

    api = config.api;

    async getApiAuthorizationAsync(apiid) {
        // トークンの認証切れに対応
        let token = null;
        const currentUser = firebaseAuth.currentUser;
        if (currentUser) {
            token = await currentUser.getIdToken();
        } else {
            console.log("ERROR");
        }

        const apikey = '';
        return {
            "Api-Id": apiid,
            "Api-Key": apikey,
            Authorization: "Bearer " + token
        };
    }

    async postAsync(apiid, command, data) {
        try {
            var url = this.api + command;
            // console.log(url, data);
            const response = await axios.post(url, data, { headers: await this.getApiAuthorizationAsync(apiid) });
            // console.log(response);
            return response;
        } catch (error) {
            console.log(error);
            return error.response;
        }
    }

    async getAsync(apiid, command) {
        try {
            var url = this.api + command;
            // console.log(url, data);
            const response = await axios.get(url, { headers: await this.getApiAuthorizationAsync(apiid) });
            // console.log(response);
            return response;
        } catch (error) {
            console.log(error);
            return error.response;
        }
    }

    // キーワードリストを取得する
    // コンポーネントロード時に一回実行する
    // (キャッシュしておきたい)
    async loadList(sid, onLoad) {
        let kana = {}
        let dicts = "$word";
        let response = await this.getAsync(sid, config.apipath + "/v1/dict?keys=" + dicts);
        if (response.status === 200) {
            var list = response.data;
            kana = list;
        } else {
            console.log(response);
        }

        response = await this.getAsync(sid, config.apipath + "/v1/dicts");
        if (response.status === 200) {
            var list = response.data;
            const result = {};
            for (const key of Object.keys(list)) {
                const item = list[key];
                result[key] = item.data;
            }
            // console.log(result);
            onLoad(kana, result);
        } else {
            console.log(response);
            onLoad(null, null);
        }
    }

    // 問診を開始する
    async start(user, sid, caseId, onResult) {
        const data = {
            id: caseId.toString(),
        }
        const response = await this.postAsync(sid, config.apipath + "/v1/monshin", data);
        if (response.status === 200) {
            try {
                // console.log(response.data);
                const result = response.data.result;
                onResult(result);
            } catch (e) {
                console.log(e);
                onResult(false);
            }
        } else {
            onResult(false);
        }
    }

    // 疾患を検索する
    async diag(user, sid, caseId, main, age, sex, selected, ddxs, exclusions, text, onResult) {
        let yes = selected.join(',');
        let no = '';
        if (yes.length === 0) {
            return;
        }
        let ddx = (ddxs ? ddxs.join(',') : "");
        let exclusion = (exclusions ? exclusions.join(',') : "");

        const data = {
            id: caseId,
            text: text,
        }
        let param = "main=" + main + "&age=" + age + "&gender=" + sex;
        param += "&positive=" + yes + "&negative=" + no;
        param += "&ddx=" + ddx + "&exclusion=" + exclusion;

        const response = await this.postAsync(sid, config.apipath + "/v1/diseases?" + param, data);
        if (response.status === 200) {
            try {
                // console.log(response.data);
                const data = response.data;
                const similars = data.results;
                const evaluates = data.recommends;
                onResult({
                    similars: similars,
                    evaluates: evaluates,
                    allSelected: selected
                });
            } catch (e) {
                console.log(e);
            }
        } else {
        }
    }

    // 鑑別診断結果を更新する
    async saveCase(user, sid, data) {
        let response = await this.postAsync(sid, config.apipath + "/v1/ddx", data);
        if (response.status === 200) {
            console.log(response.data)
            return response.data;
        } else {
            console.log(response);
            return null;
        }
    }

    // 検索履歴を取得する
    async getHistory(sid, onLoad) {
        let response = await this.getAsync(sid, config.apipath + "/v1/history");
        if (response.status === 200) {
            var data = response.data;
            return data;
        } else {
            console.log(response);
            return null;
        }
    }

    // 検索ログを取得する
    async getHistoryLog(sid, onLoad) {
        let response = await this.getAsync(sid, config.apipath + "/v1/historylog");
        if (response.status === 200) {
            var data = response.data;
            return data;
        } else {
            console.log(response);
            return null;
        }
    }

    // 検索履歴を取得する
    async getSynonyms(sid, text) {
        const data = {
            text: text,
        }
        let response = await this.postAsync(sid, config.apipath + "/v1/synonyms", data);
        if (response.status === 200) {
            return response.data;
        } else {
            console.log(response);
            return null;
        }
    }

    async postFileAsync(sid, file) {
        try {
            const apiid = sid;
            var headers = await this.getApiAuthorizationAsync(apiid);
            headers['content-type'] = 'multipart/form-data';
            const data = new FormData();
            data.append("file", file);
            const command = config.apipath + "/v1/upload";
            var url = this.api + command;
            const response = await axios.post(url, data, { headers: headers });
            return response;
        } catch (error) {
            console.log(error);
            return error.response;
        }
    }

    // ISDBをリリースする
    async publishISDB(sid) {
        const data = {
        }
        let response = await this.postAsync(sid, config.apipath + "/v1/publish", data);
        if (response.status === 200) {
            console.log(response.data);
            return response.data;
        } else {
            console.log(response);
            return null;
        }
    }

    // ISDBを取得する
    async getISDB(sid) {
        const data = {
        }
        let response = await this.postAsync(sid, config.apipath + "/v1/download", data);
        if (response.status === 200) {
            console.log(response.data);
            return response.data;
        } else {
            console.log(response);
            return null;
        }
    }

    buildIsdb(db) {
        const diseasedb = [];
        for (const item of db.isdb) {
            // 代表語に変換
            for (const symptom of item.symptoms) {
                const word = this.getWord(db.symptoms, symptom.name);
                if (word) {
                    symptom.name = word.name;
                    symptom.category = word.select;
                } else {
                    symptom.name = symptom.name.trim();
                }
            }

            // 疾患ごとのタイプを作成
            if (!diseasedb[item.name]) {
                diseasedb[item.name] = { name: item.name, rate: 0 };
            }
            diseasedb[item.name][item.type] = item.symptoms;

            // 最大のrateを設定
            if (diseasedb[item.name].rate < item.rate) {
                diseasedb[item.name].rate = item.rate;
            }
        }

        // ウェイトを計算
        const ave = {};
        for (const name of Object.keys(diseasedb)) {
            const types = diseasedb[name];
            const symptoms = [];
            const allsymptoms = [];
            // タイプの数を調べる
            const num = Object.keys(types).length - 2;
            // 全タイプの症状ごとの重みテーブル
            for (const key of Object.keys(types)) {
                if (key === "name" || key === "rate") {
                    continue;
                }
                for (const symptom of types[key]) {
                    if (!allsymptoms[symptom.name]) {
                        allsymptoms[symptom.name] = {
                            "category": "",
                            "A": "",
                            "B": "",
                            "C": "",
                            "D": "",
                        };
                    }
                    allsymptoms[symptom.name][key] = symptom.lsp;
                    allsymptoms[symptom.name]["category"] = symptom.category;
                }
            }
            // 平均を計算
            for (const name of Object.keys(allsymptoms)) {
                const symptom = {
                    category: allsymptoms[name]["category"],
                    name: name,
                    lsp: this.calcWeight(allsymptoms[name], num)
                };
                symptoms.push(symptom);
            }
            ave[name] = {
                name: name,
                rate: diseasedb[name]["rate"],
                symptoms: symptoms,
                types: types
            };
        }

        // 疾患リスト
        const diseases = [];
        for (const name of Object.keys(ave)) {
            diseases.push(ave[name]);
        }
        diseases.sort(function (a, b) {
            const ap = a.rate;
            const bp = b.rate;
            if (ap > bp) return -1;
            if (ap < bp) return 1;
            return 0;
        });

        return diseases;
    }

    // 代表語に変更
    getWord(symptoms, s) {
        for (const symptom of symptoms) {
            if (symptom.name === s || symptom.synonyms.includes(s)) {
                return symptom;
            }
        }
        return null;
    }

    // 重みを計算
    calcWeight(ws, num) {
        let w = 0;
        if (ws["A"] !== "") {
            w += ws["A"];
        }
        if (ws["B"] !== "") {
            w += ws["B"];
        }
        if (ws["C"] !== "") {
            w += ws["C"];
        }
        if (ws["D"] !== "") {
            w += ws["D"];
        }
        return w / num;
    }

    // GPTで文章を解析する
    async getResult(sid, text) {
        const data = {
            text: '以下のテキストから、次のエンティティを次の形式で抽出します。\n' +
                '年齢: <言及された年齢>\n' +
                '性別: <言及された性別>\n' +
                '症状：<症状のコンマ区切りリスト>\n' +
                '発症様式: <どのくらいの時間で発症したか、次の選択肢から選ぶ。突然, 急性, 亜急性, 緩徐, 不明>\n' +
                '受診時間: <発症してからどのくらいの時間が経っているか、次の選択肢から選ぶ。24時間未満, 1日以上7日未満, 1週以上4週未満, 1ヶ月以上12ヶ月未満, 1年以上, 不明>\n' +
                '症状推移: <発症してから状態はどう変わっているか、次の選択肢から選ぶ。急激な悪化, 悪化する, 横ばい, 改善する, 不明>\n' +
                '疾患候補: <症状から考えられる疾患のコンマ区切りリスト>\n' +
                '理由: <疾患候補を上げた理由>\n' +
                '\n' +
                '文章：\n' +
                '"""\n' + text + '\n"""\n'
        }
        let response = await this.postAsync(sid, config.apipath + "/v1/prompt", data);
        if (response.status === 200) {
            return response.data;
        } else {
            console.log(response);
            return null;
        }
    }
}

export default new MonshinService();
