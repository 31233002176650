
import React, { useEffect } from 'react';
import {
    IconButton,
    Avatar,
    Box,
    Container,
    Flex,
    Stack,
    HStack,
    VStack,
    Center,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Heading,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Divider,
    Image,
    SimpleGrid,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
    Tabs,
    TabList,
    Tab,
    FormControl,
    FormLabel,
    Textarea,
    Alert,
    AlertIcon,
} from '@chakra-ui/react';
import {
    FiHome,
    FiTrendingUp,
    FiCompass,
    FiStar,
    FiSettings,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiGrid,
} from 'react-icons/fi';
import { useNavigate, Navigate } from 'react-router-dom';
import uuid from 'react-uuid';

import { useAuthContext } from '../../Contexts/AuthContext';
import SignService from '../../Services/SignService';
import WordService from '../../Services/WordService';
import ResultApp from './result';
import SettingsApp from './Settings';
import Loading from './Components/Loading';
import HistoryApp from './History';
import HistoryLogApp from './History/log.js';
import Footer from '../../Page/footer';
import MonshinService from '../../Services/MonshinService';
import CaseService from '../../Repositories/CaseService';
import config from '../../config.js';
import InputArea from './Components/InputArea';

const FormItem = ({ label, children, ...rest }) => {
    return (
        <FormControl p="0">
            <FormLabel>{label}</FormLabel>
            {children}
        </FormControl>
    )
};

function reverse(obj) {
    const result = {};
    for (const key of Object.keys(obj)) {
        const val = obj[key];
        result[val] = key;
    }
    return result;
}

export default function SearchApp() {
    const navigate = useNavigate();
    const toast = useToast();
    const { user, items, words, kana, license } = useAuthContext();

    const [description, setDescription] = React.useState("");
    const [result, setResult] = React.useState("");

    const [value, setValue] = React.useState('')
    const [sex, setSex] = React.useState(null);
    const [age, setAge] = React.useState(null);
    const [recommends, setRecommends] = React.useState([]);
    const [subSelected, setSubSelected] = React.useState(null);
    const [subRecommends, setSubRecommends] = React.useState([]);
    const [subModal, setSubModal] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    let [isShowSetting, setShowSetting] = React.useState(false);
    let [isShowHistory, setShowHistory] = React.useState(false);
    let [isShowHistoryLog, setShowHistoryLog] = React.useState(false);

    if (!user) {
        return (<Navigate to="/signin" />);
    }

    const sid = user.uid;

    if (Object.keys(words).length === 0) {
        return (<>
            <Loading />
        </>);
    }

    if (isShowSetting) {
        return (<SettingsApp
            // space={space}
            setShowSetting={setShowSetting} />);
    }

    if (isShowHistory) {
        return (<HistoryApp
            select={(case1) => {
                navi({
                    from: "history",
                    state: case1.state,
                    result: case1.result,
                });
            }}
            setShowHistory={setShowHistory} />);
    }

    if (isShowHistoryLog) {
        return (<HistoryLogApp
            select={(case1) => {
                navi({
                    from: "log",
                    state: case1.state,
                    result: case1.result,
                });
            }}
            setShowHistory={setShowHistoryLog} />);
    }

    // @todo wordsの主要症候フラグにマッチしたものを出す。
    const mains = {
        "全身": ["発熱", "倦怠感", "体重減少", "リンパ節腫脹"],
        "頭頸部": ["頭痛", "めまい", "眼球充血", "鼻汁", "鼻閉", "咽頭痛", "耳痛", "頸部痛"],
        "胸部": ["咳嗽", "呼吸困難", "胸痛", "動悸", "胸焼け", "前胸部痛", "側胸部痛"],
        "腹部": ["食欲不振", "腹痛", "悪心", "嘔吐", "下痢", "腹部膨満感"],
        "腰背部": ["背部痛", "腰痛"],
        "泌尿器・生殖器": ["頻尿", "排尿時痛", "不正性器出血", "月経不順"],
        "手足": ["しびれ", "震え", "関節痛", "浮腫"],
        "皮膚": ["痒み", "発疹"],
        "精神": ["不眠"],
    };

    const dev = (config.dev || (license && license.role === "dev"));

    function navi(state) {
        if (state.from !== "input" || description.length === 0) {
            localStorage.setItem("pockemon:input", "");
            localStorage.setItem("pockemon:output", "{}");
        }

        // ログ記録, 回数制限チェック
        // console.log(state)
        const sid = "_public";
        MonshinService.start(user, sid, 0,
            (result) => {
                // console.log(result);
                if (result) {
                    // result画面へ
                    navigate("/result", {
                        state: state
                    });
                } else {
                    toast({
                        title: '回数制限を超えました',
                        //   description: "We've created your account for you.",
                        status: 'error',
                        position: 'bottom-right',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            });
    }

    return (
        <>
            <Box minH="100vh">
                <MobileNav
                    setShowSetting={setShowSetting}
                    setShowHistory={setShowHistory}
                    setShowHistoryLog={setShowHistoryLog}
                />
                <Container maxW={'3xl'}>
                    <Stack
                        as={Box}
                        textAlign={'center'}
                        spacing={{ base: 8, md: 14 }}
                        py={20}
                    // pb={{ base: 20, md: 36 }}
                    >
                        <Heading
                            fontWeight={600}
                            // fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                            lineHeight={'110%'}>
                            <Text as={'span'} color={'green.400'}>
                                ポケット問診{dev ? " (dev)" : null}
                            </Text>
                        </Heading>
                        <Text color={'gray.500'}>
                            症状から疾患を検索します。<br />
                            {/* 文章で症状を入力するか、年齢・性別・主訴を選択してください。 */}
                        </Text>

                        {dev ? <>
                            <FormControl>
                                <FormLabel color="green" fontWeight={'bold'}>年齢・性別・症状を文章で入力(dev)</FormLabel>
                                <InputArea
                                    loading={loading || recommends.length > 0}
                                    value={description}
                                    placeholder='文章を入力してください'
                                    onChange={(event) => {
                                        setDescription(event.target.value);
                                        localStorage.setItem("pockemon:input", event.target.value);
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            // getResult(description);
                                        }
                                    }}
                                />
                                <div style={{ padding: "4px" }} />
                                <Alert status='warning' align="left">
                                    <AlertIcon />
                                    <Text fontSize={"xs"}>
                                        患者の個人情報を入力しないようにしてください。患者の個人情報を入力される際には、法令上、当該患者から事前の同意を取得する必要があります。
                                    </Text>
                                </Alert>
                                {recommends.length === 0 ? <Button
                                    mt={4}
                                    isDisabled={loading || description.length === 0}
                                    onClick={() => {
                                        getResult(description);
                                    }}
                                >{"確定"}</Button> : <Button
                                    mt={4}
                                    onClick={() => {
                                        setDescription("");
                                        setRecommends([]);
                                    }}
                                >{"入力し直す"}</Button>}
                            </FormControl>

                            {recommends.length === 0 ? <Flex alignItems={"center"}>
                                <Divider />
                                <Text w={"600px"} color={'gray.500'}>{"もしくは、直接入力"}</Text>
                                <Divider />
                            </Flex> : null}
                        </> : null}

                        <FormControl>
                            <FormLabel color="green" fontWeight={'bold'}>年齢</FormLabel>
                            {renderNumber(null, "", "数字を入力してください", age, (v) => {
                                setAge(v.target.value);
                            }, (v) => {
                            })}
                        </FormControl>
                        <FormControl>
                            <FormLabel color="green" fontWeight={'bold'}>性別</FormLabel>
                            {renderRadioSelect(items.sex, "", sex, (v) => {
                                setSex(items.sex[v]);
                            })}
                        </FormControl>
                        <FormControl>
                            <FormLabel color="green" fontWeight={'bold'}>主訴（選択すると疾患を検索します）</FormLabel>
                            <Input variant='outline' placeholder='症状を入力して検索するか、下の選択肢から選択してください'
                                onChange={(event) => {
                                    setValue(event.target.value);
                                    if (event.target.value.length > 0) {
                                        analyzeText(event.target.value);
                                    } else {
                                        setRecommends([]);
                                    }
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        recommendSynonyms(value);
                                    }
                                }}
                            />
                            <Box
                                textAlign={'left'}>
                                {recommends.map((v, i) => {
                                    return (<Button m={1}
                                        onClick={() => {
                                            onSearch(v);
                                        }}
                                    >{v}</Button>);
                                })}
                                {(value.length === 0 && recommends.length === 0 ?
                                    <Box p={4}>{Object.keys(mains).map((v, i) => {
                                        return (
                                            <Box key={i} marginTop={2}>
                                                <FormItem label={v}>
                                                    {mains[v].map((w, j) => {
                                                        return (<Button key={i + j * 1000} m={1}
                                                            onClick={() => {
                                                                onSearch(w);
                                                            }}
                                                        >{w}</Button>);
                                                    })}</FormItem></Box>);
                                    })}</Box>
                                    : null)}
                            </Box>
                        </FormControl>
                    </Stack>
                </Container>
            </Box>

            <Modal isOpen={subModal} onClose={() => { setSubSelected(null); setSubModal(false); }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>具体的な症状を選んでください</ModalHeader>
                    <ModalBody>
                        <Box p={4}>{subRecommends.map((v, i) => {
                            return (<Button m={1}
                                onClick={() => {
                                    setSubModal(false);
                                    onSearch(v);
                                }}
                            >{v}</Button>);
                        })}</Box>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Footer />
        </>
    );

    function onSearch(v) {
        if (subSelected !== v) {
            const structure = items.structure;
            for (const key of Object.keys(structure)) {
                if (key === v) {
                    setSubSelected(v);
                    setSubRecommends(structure[key]);
                    setSubModal(true);
                    return;
                }
            }
        }

        const case1 = new CaseService();
        case1.update({
            age: age,
            sex: sex,
            main: v,
        });

        navi({
            from: "input",
            state: case1.state,
            result: case1.result,
        });
    }

    async function getResult(t) {
        setLoading(true);

        const result = await MonshinService.getResult(sid, t);
        console.log(result);
        const text = result.choices[0].message.content;
        setResult(text);

        const output = {};
        const lines = text.split("\n");
        for (const line of lines) {
            const item = line.split(":");
            const key = item[0].trim();
            console.log(item);
            output[key] = item[1].trim();
            switch (key) {
                case "年齢":
                    if (parseInt(output[key])) {
                        setAge(parseInt(output[key]));
                    }
                    break;
                case "性別":
                    setSex(output[key]);
                    break;
                case "症状":
                    const labels = await recommendSynonyms2(item[1]);
                    output["症状リスト"] = labels;
                    break;
            }
        }
        console.log(output);
        localStorage.setItem("pockemon:output", JSON.stringify(output));

        setLoading(false);
    }
    async function recommendSynonyms2(t) {
        const result = await MonshinService.getSynonyms(sid, t);
        const labels = [];
        for (const key of Object.keys(result)) {
            const synonyms = result[key];
            for (const synonym of synonyms) {
                if (!labels.includes(synonym.label)) {
                    labels.push(synonym.label);
                    break;
                }
            }
        }
        setRecommends(labels);
        return labels;
    }

    function analyzeText(t) {
        setRecommends(WordService.analyzeText(items, kana, t));
    }

    async function recommendSynonyms(t) {
        const result = await MonshinService.getSynonyms(sid, t);
        const labels = [];
        for (const synonym of result[t]) {
            labels.push(synonym.label);
        }
        setRecommends(labels);
    }

    function renderRadioSelect(list, placeholder, defValue, handleChange) {
        if (!list) {
            return <div />;
        }
        let index = 0;
        for (const key of list) {
            if (key === defValue) {
                break;
            }
            index++;
        }
        return (
            <Tabs variant='soft-rounded' colorScheme='green'
                index={index}
                onChange={(e) => { handleChange(e) }}>
                <TabList>
                    {list.map((data, index) => {
                        return <Tab key={index} value={data}>{data}</Tab>;
                    })}
                    <Tab key={"不明"} value={"不明"}>{"不明"}</Tab>
                </TabList>
            </Tabs>
        )
    }

    function renderNumber(list, labbel, placeholder, defValue, handleChange, handleEnter) {
        return (
            <Input
                placeholder={placeholder}
                value={defValue}
                onChange={handleChange}
                onEnter={handleEnter}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleEnter(e.target.value);
                    }
                }}
                allowClear />
        );
    }

}

// アプリバー
const MobileNav = ({ setShowSetting, setShowHistory, setShowHistoryLog, ...rest }) => {
    const { user, license } = useAuthContext();
    const navigate = useNavigate();

    const handleLogout = () => {
        SignService.signOut();
        navigate('/signin');
    };

    const dev = (config.dev && license && license.role === "dev");

    return (
        <>
            <Flex
                px={{ base: 4, md: 4 }}
                height="12"
                justifyContent={'space-between'}
                alignItems="center"
                bg={useColorModeValue('white', 'gray.900')}
                borderBottomWidth="1px"
                borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
                {...rest}>
                <Flex
                    justifyContent={'space-between'}
                    alignItems="center">
                </Flex>
                <HStack spacing={{ base: '0', md: '6' }}>
                    <Flex alignItems={'center'}>
                        <Button m={4} size={'sm'} variant='outline'
                            onClick={() => {
                                setShowHistory(true);
                            }}>検索履歴</Button>
                        {dev ? <Button mr={4} size={'sm'} variant='outline'
                            onClick={() => {
                                setShowHistoryLog(true);
                            }}>検索ログ</Button> : null}
                        <Menu>
                            <MenuButton
                                py={2}
                                transition="all 0.3s"
                                _focus={{ boxShadow: 'none' }}>
                                <HStack>
                                    {/* <Image
                                        borderRadius='full'
                                        boxSize='40px'
                                        src={user.photoURL}
                                        alt={user.displayName}
                                        referrerpolicy="no-referrer”"
                                    /> */}
                                    <Avatar
                                        size={'sm'}
                                        src={user.photoURL}
                                    />
                                    <Box display={{ base: 'none', md: 'flex' }}>
                                        <FiChevronDown />
                                    </Box>
                                </HStack>
                            </MenuButton>
                            <MenuList
                                bg={useColorModeValue('white', 'gray.900')}
                                borderColor={useColorModeValue('gray.200', 'gray.700')}>
                                {/* <MenuItem onClick={() => {
                                    setShowHistory(true);
                                }}>検索履歴</MenuItem> */}
                                <MenuItem onClick={() => {
                                    setShowSetting(true);
                                }}>設定</MenuItem>
                                <MenuDivider />
                                <Link isExternal href="https://docs.google.com/forms/d/e/1FAIpQLSderfg0jeK2dLmNW2Z1q_sRve8OhCSD02wGZcp4rHwY9szIjQ/viewform">
                                    <MenuItem>{"フィードバックを送る"}</MenuItem>
                                </Link>
                                <MenuDivider />
                                <MenuItem onClick={() => {
                                    handleLogout();
                                }}>ログアウト</MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                </HStack>
            </Flex>
        </>
    );
};
