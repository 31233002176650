
import React, { useEffect } from 'react';
import {
    Container,
    Avatar,
    Box,
    CloseButton,
    Flex,
    Stack,
    HStack,
    VStack,
    Center,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Heading,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuGroup,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    calc,
    Image,
    SimpleGrid,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,

    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
    Progress,

} from '@chakra-ui/react';
import { Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import {
    FiMoreVertical,
    FiTool,
    FiCheck,
    FiTrash,
    FiEdit2,
    FiCheckSquare,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiGrid,
    FiUpload,
    FiDownload,
    FiBook,
    FiTrendingUp,
    FiEdit,
} from 'react-icons/fi';
import { useAuthContext } from '../../../Contexts/AuthContext';
import LogService from '../../../Repositories/LogService';

import config from '../../../config';
import MonshinService from '../../../Services/MonshinService';
import CaseService from '../../../Repositories/CaseService';

function NumInput({ defValue, onEnter }) {
    let [value, setValue] = React.useState(defValue);
    return (
        <Input size={"sm"}
            type={"number"}
            value={value}
            onChange={(v) => {
                setValue(v.target.value);
            }}
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    if (defValue !== value) {
                        onEnter(value);
                    }
                }
            }}
            onBlur={() => {
                if (defValue !== value) {
                    onEnter(value);
                }
            }}
            allowClear />
    )
}

function TdItem({ label, children }) {
    return (
        <Flex py={2}>
            <Box w={"100px"}><Text fontSize={"xs"}>{label}</Text></Box>
            <Box flex={1}>{children}</Box>
        </Flex>
    );
}

// ワークスペースの設定を行うアプリ
export default function DiseaseManager({
    space, children,
}) {
    const gray50 = useColorModeValue('gray.50', 'gray.700');
    let [loading, setLoading] = React.useState(false);
    let { db, setDb, items } = useAuthContext();
    const toast = useToast();

    let [status, setStatus] = React.useState("noload");
    let [diseases, setDeseases] = React.useState([]);
    let [searchText, setSearchText] = React.useState("");
    let [searchText2, setSearchText2] = React.useState("");
    let [disease, setDesease] = React.useState(null);
    let [isCase, setIsCase] = React.useState(false);
    let [accs, setAccs] = React.useState([]);

    let [view, setView] = React.useState("s");

    useEffect(() => {
        if (db) {
            const diseases = MonshinService.buildIsdb(db);
            setDb(db);
            setDeseases(diseases);
            calc(db, diseases);
            setStatus("load");
        }
    }, []);

    function getCategoryNo(c) {
        if (!c) return "：";
        if (c === "keyword") return "・";
        return c.toUpperCase();
    }

    let n = 0;
    let h1 = "calc(100vh - 48px)";

    return (<Box
        bg={useColorModeValue('white', 'gray.900')}
        w={'calc(100vw - 200px)'}
    >
        <Flex
            h={h1}
        >
            <Box
                w={"250px"}
                h={h1}
                overflowX="auto"
                overflowY="auto"
            >
                <Flex px={4} py={4} h={"64px"}>
                    <Input size={"sm"}
                        variant='outline'
                        placeholder='検索...'
                        value={searchText}
                        onChange={(event) => {
                            setSearchText(event.target.value);
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                    />
                    <Button size={"sm"} onClick={() => { setSearchText("") }}>{"クリア"}</Button>
                </Flex>
                <Box px={4} h={"48px"}>
                    <Button size={"sm"} onClick={() => { setIsCase(false) }} colorScheme={!isCase ? "blue" : "gray"}>{"すべて"}</Button>
                    <Button size={"sm"} onClick={() => { setIsCase(true) }} colorScheme={isCase ? "blue" : "gray"}>{"症例ありのみ"}</Button>
                </Box>
                {loading ? <>
                    loading...
                </>
                    : <TableContainer
                        overflowX="auto"
                        overflowY="auto"
                        h={"calc(100vh - 48px - 64px - 48px)"}
                    >
                        <Table size={"sm"}>
                            <Thead position="sticky" top={0} zIndex={1}>
                                <Tr>
                                    <Th p={0}>有病率</Th>
                                    <Th>疾患</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {diseases.map((d, i) => {
                                    if (n > 40) {
                                        return null;
                                    }
                                    const name = d.name;
                                    if (searchText.length > 0) {
                                        if (name.indexOf(searchText) === -1) {
                                            return null;
                                        }
                                    }
                                    if (isCase) {
                                        let match = false;
                                        for (const item of db.cases) {
                                            if (item.name === d.name) {
                                                match = true;
                                            }
                                        }
                                        if (!match) {
                                            return null;
                                        }
                                    }

                                    n++;
                                    return (
                                        <Tr key={i} onClick={() => {
                                            setDesease(name);
                                            setSearchText2(name);
                                        }}
                                            bgColor={disease === name ? "#C4F1F9" : "initial"}
                                        >
                                            <Td>{d.rate}</Td>
                                            <Td>{name}</Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>}
            </Box>

            <Box
                flex={1}
                h={h1}
                overflowX="auto"
                overflowY="auto"
            >
                <Flex p={4} h={"64px"} alignItems={"center"}>
                    {disease}

                    <div style={{ flexGrow: 1 }} />

                    <Button onClick={() => { setView("s") }} colorScheme={view === "s" ? "blue" : "gray"}>{"症状"}</Button>
                    <Button onClick={() => { setView("e") }} colorScheme={view === "e" ? "blue" : "gray"}>{"症例"}</Button>
                </Flex>

                {view === "s" ? renderWeight() : renderCase()}
            </Box>

            <Box
                flex={1}
                overflowY="auto"
                bgColor={"whitesmoke"}>
                {renderAccuracy()}
            </Box>
        </Flex>
    </Box>);

    function renderWeight() {
        let symptomlist = [];
        if (disease) {
            for (const item of diseases) {
                if (item.name === disease) {
                    symptomlist = item.symptoms;
                    const symptoms = item.types;
                    for (const d of symptomlist) {
                        d.wtype = {
                            "A": getWeight(symptoms, "A", d.name),
                            "B": getWeight(symptoms, "B", d.name),
                            "C": getWeight(symptoms, "C", d.name),
                            "D": getWeight(symptoms, "D", d.name),
                        };
                    }
                    break;
                }
            }
            symptomlist.sort(function (a, b) {
                const as = getCategoryNo(a.category) + a.name;
                const bs = getCategoryNo(b.category) + b.name;
                if (as > bs) return -1;
                if (as < bs) return 1;
                return 0;
            });
        }

        return (disease ?
            <>
                <TableContainer
                    overflowX="auto"
                    overflowY="auto"
                    h={"calc(100vh - 48px - 64px)"}
                >
                    <Table size={"sm"}>
                        <Thead position="sticky" top={0} zIndex={1}>
                            <Tr>
                                <Th>選択肢</Th>
                                <Th>症状</Th>
                                <Th>重み</Th>
                                <Th>A</Th>
                                <Th>B</Th>
                                <Th>C</Th>
                                <Th>D</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {symptomlist.map((d, j) => {
                                return (
                                    <Tr key={j}>
                                        <Td maxW={"100px"}>{d.category}</Td>
                                        <Td maxW={"100px"}>{d.name}</Td>
                                        <Td>{d.lsp.toFixed(2)}</Td>
                                        <Td px={0}>
                                            <NumInput
                                                defValue={d.wtype["A"]}
                                                onEnter={(v) => {
                                                    updateWeight(disease, "A", d, v);
                                                }} />
                                        </Td>
                                        <Td px={0}>
                                            <NumInput
                                            defValue={d.wtype["B"]}
                                                onEnter={(v) => {
                                                    updateWeight(disease, "B", d, v);
                                                }} />
                                        </Td>
                                        <Td px={0}>
                                            <NumInput
                                            defValue={d.wtype["C"]}
                                                onEnter={(v) => {
                                                    updateWeight(disease, "C", d, v);
                                                }} />
                                        </Td>
                                        <Td px={0}>
                                            <NumInput
                                            defValue={d.wtype["D"]}
                                                onEnter={(v) => {
                                                    updateWeight(disease, "D", d, v);
                                                }} />
                                        </Td>
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </> : null);
    }

    function renderCase() {
        let caselist = [];
        if (disease) {
            for (const item of db.cases) {
                if (item.name === disease) {
                    caselist.push(item);
                }
            }
        }

        return (disease ?
            <>
                <TableContainer
                    overflowX="auto"
                    overflowY="auto"
                    h={"calc(100vh - 48px - 64px)"}
                >
                    <Table size={"sm"}>
                        <Tbody>
                            {caselist.map((d, j) => {
                                const result = diagItem(db, diseases, d);
                                let w = null;
                                for (const item of result) {
                                    if (item.name === d.name) {
                                        w = item;
                                    }
                                }

                                return (
                                    <Tr>
                                        <Td
                                            minW={"300px"}
                                            whiteSpace={"pre-wrap"}
                                        >
                                            <TdItem label={"ビネット"}>{d.vignette}</TdItem>
                                            <TdItem label={"スコア"}>{w ? w.w.toFixed(2) : "圏外"}</TdItem>
                                            <TdItem label={"年齢"}>{d.age}</TdItem>
                                            <TdItem label={"性別"}>{d.sex}</TdItem>
                                            <TdItem label={"発症様式"}>{d.onset}</TdItem>
                                            <TdItem label={"発症期間"}>{d.duration}</TdItem>
                                            <TdItem label={"症状推移"}>{d.time_course}</TdItem>
                                            <TdItem label={"間欠期"}>{d.repeat}</TdItem>
                                            <TdItem label={"主訴"}>{d.main}</TdItem>
                                            <TdItem label={"他の症状"}>{d.symptoms}</TdItem>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </> : null);
    }

    function getWeight(symptoms, t, name) {
        if (symptoms[t]) {
            for (const symptom of symptoms[t]) {
                if (symptom.name === name) {
                    return symptom.lsp;
                }
            }
        }
        return "";
    }

    function updateWeight(disease, type, d, w) {
        const name = d["name"];
        const category = d["category"];

        // isdbのデータを変更してsetupする
        const isdb = db.isdb;
        let changed = false;
        let match = false;
        for (const item of isdb) {
            // 疾患＋先生データのレコードがあれば更新
            if (item.name === disease && item.type === type) {
                // 指定の症状の重みを変更
                for (const symptom of item.symptoms) {
                    if (symptom.name === name) {
                        symptom.lsp = w !== "" ? Number(w) : w;
                        match = true;
                        break;
                    }
                }
                // 症状がない場合は症状と重みを追加
                if (!match) {
                    item.symptoms.push({
                        category: category,
                        name: name,
                        lsp: w !== "" ? Number(w) : w
                    });
                    match = true;
                    changed = true;
                }
                break;
            }
        }
        // 疾患＋先生データのレコードがない場合は先生レコードを追加
        if (!match) {
            isdb.push({
                name: disease,
                type: type,
                symptoms: [{
                    category: category,
                    name: name,
                    lsp: Number(w)
                }]
            });
            changed = true;
        }

        // 症状データの追加、もしくは、先生データの追加があったら再構築
        const diseases = MonshinService.buildIsdb(db, true);
        setDb(db);
        setDeseases(diseases);
        calc(db, diseases);
    }

    ///////////////////////////////////////////////

    function renderAccuracy() {
        return (<Box>
            <Flex h={"128px"}>
                {renderSummary()}
                <div style={{ flexGrow: 1 }} />
            </Flex>
            <Flex px={4} h={"48px"}>
                <Input size={"sm"}
                    variant='outline'
                    placeholder='検索...'
                    value={searchText2}
                    onChange={(event) => {
                        setSearchText2(event.target.value);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                        }
                    }}
                />
                <Button size={"sm"} onClick={() => { setSearchText2("") }}>{"クリア"}</Button>
            </Flex>
            <TableContainer
                overflowX="auto"
                overflowY="auto"
                h={"calc(100vh - 48px - 128px - 48px)"}
            >
                <Table size={"sm"}>
                    <Thead position="sticky" top={0} zIndex={1}>
                        <Tr>
                            <Th>症例の疾患</Th>
                            <Th>順位</Th>
                            <Th>スコア</Th>
                            <Th>重み</Th>
                            <Th>症例の症状の評価</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {accs.map((d, j) => {
                            if (searchText2.length > 0) {
                                if (d.name.indexOf(searchText2) === -1) {
                                    return null;
                                }
                            }
                            return (
                                <Tr>
                                    <Td wordWrap={"break-word"} whiteSpace={"normal"}>{d.name}</Td>
                                    <Td>{d.rank}</Td>
                                    <Td>{d.weight.toFixed(2)}</Td>
                                    <Td>{d.w}</Td>
                                    <Td wordWrap={"break-word"} whiteSpace={"normal"}>{d.inputw}</Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>);
    }

    function renderSummary() {
        const result = {
            total: 0,
            one: 0,
            three: 0,
            five: 0,
            ten: 0,
            out: 0,
        };
        for (const acc of accs) {
            result.total++;
            if (acc.rank === 1) { result.one++; }
            else if (acc.rank <= 3) { result.three++; }
            else if (acc.rank <= 5) { result.five++; }
            else if (acc.rank <= 10) { result.ten++; }
            else { result.out++; }
        }
        const yuko = result.total - result.out;
        return (
            <Box p={4} w={"full"}>
                <StatGroup>
                    <Stat>
                        <StatLabel>{"1位"}</StatLabel>
                        <StatNumber>{result.one}</StatNumber>
                        <StatHelpText>
                            <Progress value={result.one / yuko * 100} />
                        </StatHelpText>
                    </Stat>
                    <Stat>
                        <StatLabel>{"3位以内"}</StatLabel>
                        <StatNumber>{result.three}</StatNumber>
                        <StatHelpText>
                            <Progress value={result.three / yuko * 100} />
                        </StatHelpText>
                    </Stat>
                    <Stat>
                        <StatLabel>{"5位以内"}</StatLabel>
                        <StatNumber>{result.five}</StatNumber>
                        <StatHelpText>
                            <Progress value={result.five / yuko * 100} />
                        </StatHelpText>
                    </Stat>
                    <Stat>
                        <StatLabel>{"10位以内"}</StatLabel>
                        <StatNumber>{result.ten}</StatNumber>
                        <StatHelpText>
                            <Progress value={result.ten / yuko * 100} />
                        </StatHelpText>
                    </Stat>
                </StatGroup>
                <Box w={"full"}>
                    <Text fontSize={"md"}>
                        圏内:{yuko}{" / "}
                        圏外:{result.out}{" / "}
                        総数:{result.total}
                    </Text>
                </Box>
            </Box>);
    }

    async function doCalc() {
        await calc(db, diseases);
        setStatus("updated");
        console.log("end")
    }

    // 精度を計算する
    // コモンディジーズがそれぞれの症例の症状を選択した場合に、何位に位置するかを示す。
    async function calc(db, diseases) {
        const accs = [];

        // 全症例について症例の疾患ごとの順位と重みを計算
        for (const item of db.cases) {
            if (item.main.length > 0) {
                // 症例の症状リストで診断実行
                const result = diagItem(db, diseases, item, item.name === "インフルエンザ");

                // if (item.name === "インフルエンザ") {
                //     console.log(result)
                // }

                // 症例の疾患の順位と重みを取得
                let match = false;
                let rank = 1;
                for (const d of result) {
                    if (d.name === item.name) {
                        accs.push(
                            {
                                name: d.name,
                                rank: rank,
                                weight: d.w,
                                input: d.input.join(","),
                                w: d.orgw,
                                inputw: JSON.stringify(d.wlist),
                            }
                        );
                        // console.log(d, result)
                        match = true;
                        break;
                    }
                    rank++;
                }
                if (!match) {
                    accs.push(
                        {
                            name: item.name,
                            rank: 999,
                            weight: 0,
                            input: "",
                            w: 0,
                            inputw: "",
                        }
                    );
                }
            }
        }
        setAccs(accs);
    }

    function diagItem(db, diseases, item, isLog) {
        // 症状リストを取得
        let ss = item.main + "," + item.symptoms;

        const case1 = new CaseService();
        const state = case1.update({
            age: item.age,
            sex: item.sex,
            onset: item.onset,
            duration: item.duration,
            time_course: item.time_course,
            repeat: item.repeat,
        }, items);

        if (state.ageId !== "年齢不明") ss += "," + state.ageId;
        if (state.sex !== "性別不明") ss += "," + state.sex;
        if (state.onset !== "不明") ss += "," + state.onset;
        if (state.duration !== "不明") ss += "," + state.duration;
        if (state.time_course !== "不明") ss += "," + state.time_course;
        if (state.repeat !== "不明") ss += "," + state.repeat;

        const symptoms = ss.split((/[,、\s]+/));
        // if (isLog) console.log(state, symptoms);

        // 診断実行
        const result = diag(db, diseases, symptoms[0], symptoms, isLog);
        return result;
    }

    // 推論
    function diag(db, diseases, main, symptoms, isLog) {
        const result = [];
        for (const item of diseases) {
            let wlist = {};
            let match = false;
            for (const symptom of item.symptoms) {
                if (main === symptom.name) {
                    match = true;
                }
            }
            if (match) {
                const w = getW(db, item, symptoms, wlist, isLog);
                result.push({
                    name: item.name,
                    w: w + item.rate * item.rate,
                    def: item.symptoms,
                    input: symptoms,
                    orgw: w,
                    rate: item.rate,
                    wlist: wlist,
                });
            }
        }

        result.sort(function (a, b) {
            const as = a.w;
            const bs = b.w;
            if (as > bs) return -1;
            if (as < bs) return 1;
            return 0;
        });
        // if (isLog) console.log(result)

        return result;
    }

    function getW(db, item, symptoms, wlist, isLog) {
        let w = 0;
        for (let name of symptoms) {
            name = name.trim();
            let match = false;
            for (const symptom of item.symptoms) {
                if (name === symptom.name) {
                    w += symptom.lsp;
                    match = true;
                    wlist[name] = symptom.lsp;
                    // if (isLog && item.name === "インフルエンザ") console.log(item.name, name, symptom.lsp);
                }
            }
            if (!match) {
                const lsp = noW(db, name);
                w += lsp;
                wlist[name] = lsp;
                // if (isLog && item.name === "インフルエンザ") console.log(item.name, name, lsp);
            }
        }
        // if (isLog && item.name === "インフルエンザ") console.log(w);
        return w;
    }

    function noW(db, s) {
        const nowrds = ['男性', '女性',
            '乳児', '幼児', '小児', '若年', '中年', '高齢', '超高齢', '年齢不問',
            '親', '成人',
            '日単位', '週単位', '月単位', '年単位',
            '突然', '急性', '亜急性', '緩徐', '横ばい',
            '悪化する', '改善する'];
        if (nowrds.includes(s)) {
            return 0;
        }
        for (const symptom of db.symptoms) {
            if (symptom.name === s) {
                return symptom.defw;
            }
        }
        return -9;
    }
}
