import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { googleAuthProvider } from '../firebase.js'

const SignService = {
    // ログイン＆登録ボタン押下時に呼ぶ
    signInWithGoogle: () => {
        const auth = getAuth();
        signInWithPopup(auth, googleAuthProvider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // ...
                // console.log("signIn.");
                // console.log(user);
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
                console.log(`signIn FAILED. (${error})`);
            });
    },

    // ログアウトする
    signOut: () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });
    }
}

export default SignService;